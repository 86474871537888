.hideInDesktop {
    display: none
}

@media screen and (max-width: 600px) {
    .hideInMobile {
        display: none;
    }

    .hideInDesktop {
        display: block
    }

}