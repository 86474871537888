.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: red; /* Adjust the background color as needed */
    color: white; /* Adjust the text color as needed */
  }
  
  .main-title {
    flex: 1;
  }
  
  .right-section {
    display: flex;
    gap: 20px;
  }
  
  .register,
  .admin-login {
    font-size: 14px; /* Adjust the font size as needed */
  }

  /* Default styles for larger screens */
.main-title h2 {
  font-size: 24px; /* Adjust the font size as needed */
}

.main-hidden {
  display: none;
}

/* Media query for smaller screens (adjust the max-width as needed) */
@media screen and (max-width: 600px) {
  .main-title h2 {
    font-size: 20px; /* Adjust the font size for smaller screens */
  }

  .main-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .mobile-header {
    align-items: 'center';
  }

  .right-section {
    display: none;
  }

  .hidden {
    display: none;
  }

  .main-hidden {
    display: inline-block;
  }
}
